<template>
  <div class="app-container">
    <el-form v-model="searchForm" inline size="small">
      <el-form-item label="平台">
        <el-select v-model="searchForm.platform" filterable clearable placeholder="请选择平台" @change="getSite">
          <el-option v-for="item in platformList" :key="item.platform" :value="item.platform" :label="item.platform"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="站点">
        <el-select v-model="searchForm.site" filterable clearable @change="getAccount" placeholder="请先选择平台">
          <el-option v-for="item in siteList" :key="item.site" :value="item.site" :label="item.site"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="账号">
        <el-select v-model="searchForm.account" filterable clearable multiple collapse-tags placeholder="请先选择站点">
          <el-option v-for="item in accountList" :key="item" :value="item" :label="item"></el-option>
        </el-select>
      </el-form-item>
      <el-button type="primary" size="small" @click="search">查询</el-button>
      <el-button type="success" size="small" @click="addConf">添加配置</el-button>
    </el-form>
    <el-table :data="dataList" border size="small" height="calc(100vh - 220px)" :header-cell-style="{background: '#41A1FF', color:'#fff'}">
      <el-table-column label="平台" prop="platform" width="120" align="center"></el-table-column>
      <el-table-column label="站点" prop="site" width="120" align="center"></el-table-column>
      <el-table-column label="账号" prop="account" width="180" align="center"></el-table-column>
      <el-table-column label="人员" prop="userNames"></el-table-column>
      <el-table-column label="创建人" prop="createBy" width="150" align="center"></el-table-column>
      <el-table-column label="创建时间" prop="createTime" width="140" align="center"></el-table-column>
      <el-table-column label="启用/禁用" width="120" align="center">
        <template slot-scope="scope">
          <el-tooltip effect="dark" :content="scope.row.isDisabled===0?'启用':'禁用'" placement="top">
            <el-switch v-model="scope.row.isDisabled" @change="changeStatus($event, scope.row)"
              :active-value="0" :inactive-value="1"
              active-color="#13ce66" inactive-color="#ff4949">
            </el-switch>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="120" align="center">
        <template slot-scope="scope">
          <el-tooltip effect="dark" content="分配人员" placement="top">
            <!-- <svg-icon icon-class="user-number" class="table_icons"></svg-icon> -->
            <i class="table_icons el-icon-user" @click="addUser(scope.row)"></i>
          </el-tooltip>
          <el-tooltip effect="dark" content="删除" placement="top">
            <i class="table_icons el-icon-delete" @click="delNode(scope.row.id)"></i>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <div style="margin-top: 1em; text-align: center">
      <el-pagination
        :page-size="pageSize"
        :page-sizes="[10, 20, 50, 100]"
        :current-page="page"
        :total="total"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrenChange">
      </el-pagination>
    </div>
    <!-- 新增配置 -->
    <add-panel v-bind.sync="addOptions" @success="search"></add-panel>
    <!-- 分配人员 -->
    <user-panel v-bind.sync="userOptions" @success="confUserSuccess"></user-panel>
  </div>
</template>
<script>
import { getPlatformInfo, getPlatformSitePage, platformSiteDelete } from '@/api/platformManagement'
// import { getUserInfoByName } from '@/api/login'
import { deepClone } from '@/utils/index'
import addPanel from './addPanel'
import userPanel from './addUser'
export default {
  components: { addPanel, userPanel },
  data() {
    return {
      searchForm: {
        platform: '',
        site: '',
        account: [] // 账号应该支持多选查询
      },
      platformList: [],
      siteList: [],
      accountList: [],
      dataList: [],
      page: 1,
      pageSize: 10,
      total: 0,
      loading: false,
      // allUsers: [],
      addOptions: {
        visible: false,
        siteList: [],
        allUsers: []
      },
      userOptions: {
        _id: null,
        isDisabled: null,
        visible: false,
        allUsers: [],
        selectedIds: ''
      }
    }
  },
  computed: {
    allUsers() {
      return this.$store.getters.allUserList
    }
  },
  mounted() {
    this.search()
    this.getPlatSiteAccount()
    // this.getAllUser()
    if (this.allUsers && this.allUsers.length < 1) {
      this.$store.dispatch('GetAllUser')
    }
  },
  methods: {
    getPlatSiteAccount() {
      getPlatformInfo({ pageNum: 1, pageSize: 10000 }).then(res => {
        if (res.code === '000000' && res.data) {
          this.platformList = res.data
        }
      })
    },
    // getAllUser() {
    //   getUserInfoByName({ pageNum: 1, pageSize: 10000 }).then(res => {
    //     if (res.code === '000000' && res.data) {
    //       this.allUsers = res.data.list
    //     }
    //   }).catch()
    // },
    getSite(val) {
      this.siteList = []
      this.accountList = []
      this.searchForm.site = ''
      this.searchForm.account = ''
      if (val) {
        this.siteList = this.platformList.filter(item => item.platform === val)[0].siteArr
      }
    },
    getAccount(val) {
      this.accountList = []
      this.searchForm.account = ''
      if (val) {
        const accountStr = this.siteList.filter(item => item.site === val)[0].accountArr
        this.accountList = accountStr.split(',')
      }
    },
    search() {
      console.log(this.searchForm)
      if (this.page === 1) {
        this.getDataList()
      } else {
        this.handleCurrenChange(1)
      }
    },
    getDataList() {
      const params = deepClone(this.searchForm)
      params.pageSize = this.pageSize
      params.pageNum = this.page
      params.account = params.account.length ? params.account.join(',') : ''
      getPlatformSitePage(params).then(res => {
        if (res.code === '000000' && res.data) {
          this.dataList = res.data.list
          this.total = res.data.total
        }
      })
    },
    handleSizeChange(size) {
      this.pageSize = size
      this.getDataList()
    },
    handleCurrenChange(page) {
      this.page = page
      this.getDataList()
    },
    changeStatus(val, item) {
      const str = val === 0 ? '是否启用该配置？' : '是否禁用该配置？'
      this.$confirm(str, '提示', {
        type: 'warning',
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(() => {
        console.log(val + ':' + item.id)
      }).catch(() => {
        item.isDisabled = val === 0 ? 1 : 0
      })
    },
    addConf() {
      this.addOptions = {
        visible: true,
        siteList: this.platformList,
        allUsers: this.allUsers
      }
    },
    delNode(id) {
      this.$confirm('确定删除该条配置数据？', '提示', {
        type: 'warning',
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(() => {
        platformSiteDelete(id).then(res => {
          if (res.code === '000000') {
            this.$message.success(res.message)
            this.search()
          }
        }).catch()
      })
    },
    addUser(item) {
      this.userOptions = {
        visible: true,
        _id: item.id,
        isDisabled: item.isDisabled,
        allUsers: this.allUsers,
        selectedIds: item.userIds ? item.userIds : ''
      }
    },
    confUserSuccess() {
      this.userOptions.visible = false
      this.search()
    }
  }
}
</script>
