<template>
  <div v-if="visible">
    <el-dialog v-ismove :visible="true" title="配置" width="880px"
      :before-close="closeTab" :close-on-click-modal="false">
      <div>
        <el-tag closable type="warning" style="font-size: 13px;">
          <i class="el-icon-warning" style="font-size: 16px"></i>
          若只勾选平台，则只配置平台，若勾选了站点（没有选择账号），则只配置平台-站点，若勾选了站点并选择了账号则配置平台-站点-账号
        </el-tag>
        <div style="max-height: 430px; overflow-y: auto;margin-top:5px; border-bottom: 1px solid #EBEEF5">
          <el-collapse>
            <el-collapse-item v-for="item in siteList" :key="item.platform" :name="item.platform">
              <template slot="title">
                <el-checkbox v-model="platSelect[item.platform]">{{ item.platform }}</el-checkbox>
              </template>
              <!-- 平台下的站点 -->
              <el-row v-for="site in item.siteArr" :key="site.site" style="padding: 6px 24px;">
                <el-checkbox
                  v-model="siteSelect[item.platform+'-'+site.site]"
                  @change="handleCheckSiteChange(item.platform+'-'+site.site)"
                  style="min-width: 100px;">
                  {{ site.site }}
                </el-checkbox>
                <el-select v-model="accountSelect[item.platform+'-'+site.site]"
                  multiple
                  @change="handleCheckAccountChange(accountSelect[item.platform+'-'+site.site], item.platform+'-'+site.site, site.accountArr)"
                  size="mini" style="width: calc(100% - 130px);">
                  <el-option value="all">全选</el-option>
                  <el-option v-for="account in site.accountArr.split(',')" :key="account" :value="account" :label="account"></el-option>
                </el-select>
              </el-row>
            </el-collapse-item>
          </el-collapse>
        </div>
        <el-form ref="formData" :model="formData" :rules="rules" size="small" label-width="100px" style="margin-top:12px">
          <el-form-item label="分配人员" prop="users">
            <el-select v-model="formData.users" multiple filterable clearable remote :remote-method="remoteMethod" value-key="userId" style="width: 90%">
              <el-option v-for="item in userList" :key="item.userId"
                :label="item.name+'('+item.username+')'" :value="item">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="状态">
            <el-radio-group v-model="formData.isDisabled">
              <el-radio :label="0">启用</el-radio>
              <el-radio :label="1">禁用</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer">
        <el-button size="small" @click="closeTab">取消</el-button>
        <el-button type="primary" size="small" :loading="waiting" @click="submit">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import * as validFn from '@/utils/validate'
import { deepClone } from '@/utils/index'
import { platformSiteAdd } from '@/api/platformManagement'
export default {
  data() {
    return {
      userList: [],
      checkedList: [],
      platSelect: {}, // 选中的平台，{ 'amazon': true }
      siteSelect: {}, // 选中的站点，平台+站点 { 'amazon-AC': true }
      accountSelect: {}, // 选中的账号，平台+站点 { 'amazon-AC': true }
      formData: {
        users: [],
        isDisabled: 0
      },
      rules: {
        users: [validFn.requiredM()]
      },
      waiting: false
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    siteList: {
      type: Array,
      default: function() {
        return []
      }
    },
    allUsers: {
      type: Array,
      default: function() {
        return []
      }
    }
  },
  watch: {
    'visible'(val) {
      if (val) {
        // console.log(this.siteList)
        // 人员数据初始截取100，搜索时全部查找
        // this.userList = this.allUsers.slice(0, 100)
      }
    }
  },
  methods: {
    closeTab() {
      this.$emit('update:visible', false)
    },
    handleCheckSiteChange(name) {
      // console.log(this.platSelect)
      // console.log(name)
      // 选中时，其对应的平台也需被选中(当平台下站点全部取消选中时，平台是否要取消选中状态？？需确定传参结构)
      console.log(this.siteSelect[name])
      if (this.siteSelect[name]) {
        this.$set(this.platSelect, name.split('-')[0], true)
      } else {
        // 判断siteSelect下，已该platform开头的数据是否还有为true，没有则取消平台选中
        console.log(this.siteSelect)
        const platform = name.split('-')[0]
        let has = false
        Object.keys(this.siteSelect).forEach(key => {
          if (key.indexOf(platform) > -1 && this.siteSelect[key] === true) {
            has = true
          }
        })
        // 取消平台选中
        if (!has) {
          // delete this.platSelect[platform] // 这个可以实现，先选平台，取消站点时，不会取消平台的选中状态，不解！
          this.platSelect[platform] = false // 这个 取消所有站点会取消平台的选中状态，不管是否先选的平台
        }
      }
    },
    // name: accountSelect的值（all 全选，处理选中和反选），plat_site：平台站点名； accountStr 账号字符串，处理全选
    handleCheckAccountChange(name, plat_site, accountStr) {
      // 全选需处理反选(all 会被选中，所以长度-1)
      if (name.indexOf('all') > -1) {
        if (this.accountSelect[plat_site].length - 1 < accountStr.split(',').length) {
          this.accountSelect[plat_site] = accountStr.split(',')
        } else {
          this.accountSelect[plat_site] = []
        }
      }
    },
    // 过滤用户
    remoteMethod(val) {
      if (val) {
        setTimeout(() => {
          this.userList = this.allUsers.filter(item => {
            return item.name && (item.name.indexOf(val) > -1 || item.username.toLowerCase().indexOf(val.toLowerCase()) > -1)
          })
          // 将已选的user拼到userList
          if (this.formData.users.length > 0) {
            this.formData.users.forEach(item => {
              if (this.userList.filter(el => el.userId === item.userId).length === 0) {
                this.userList.unshift(item)
              }
            })
          }
        }, 200)
      }
    },
    // 数据规则：
    // 可以只选平台
    // 可以只选平台 + 站点，此时该数据对应的平台就不是独立的数据了，只能是 platform+site
    // 选择平台+站点+账号，此时该数据对应的平台，站点就不是独立数据了，只能是platform+site+account
    // 数据维度到账号层，一站点对多账号需拆分
    submit() {
      // 平台，站点至少有一个有效配置
      this.$refs.formData.validate(valid => {
        if (valid) {
          // 挑选出 账号accountSelect[plat_site] 有值的，并且其对应的站点勾选，此数据才有效
          const effective_data = {}
          Object.keys(this.accountSelect).forEach(key => {
            if (this.accountSelect[key].length > 0 && this.siteSelect[key] === true) {
              effective_data[key] = this.accountSelect[key]
            }
          })
          console.log(effective_data)
          const json_data = {
            platform: '',
            site: '',
            account: '',
            isDisabled: this.formData.isDisabled,
            userIds: this.formData.users.map(el => el.userId).join(','),
            userNames: this.formData.users.map(el => el.name).join(',')
          }
          // 先从账号开始组装数据，再处理对应的平台站点数据是否需要加入
          const params = []
          Object.keys(effective_data).forEach(key => {
            const platform = key.split('-')[0]
            const site = key.split('-')[1]
            effective_data[key].forEach(item => {
              const json = deepClone(json_data)
              json.platform = platform
              json.site = site
              json.account = item
              params.push(json)
            })
          })
          // 处理站点数据（只选了站点，未选择账号）
          Object.keys(this.siteSelect).forEach(key => {
            if (this.siteSelect[key]) {
              const platform = key.split('-')[0]
              const site = key.split('-')[1]
              if (params.filter(el => el.platform === platform && el.site === site).length === 0) {
                const json = deepClone(json_data)
                json.platform = platform
                json.site = site
                json.account = ''
                params.push(json)
              }
            }
          })
          // 处理平台（只选了平台）
          Object.keys(this.platSelect).forEach(key => {
            if (this.platSelect[key]) {
              if (params.filter(el => el.platform === key).length === 0) {
                const json = deepClone(json_data)
                json.platform = key
                json.site = ''
                json.account = ''
                params.push(json)
              }
            }
          })
          if (params.length === 0) {
            this.$message.warning('请勾选有效的平台、站点、账号配置')
          } else {
            console.log(params)
            this.waiting = true
            platformSiteAdd(params).then(res => {
              if (res.code === '000000') {
                this.$message.success(res.message)
                this.$emit('update:visible', false)
                this.$emit('success')
              }
              this.waiting = false
            }).catch(() => {
              this.waiting = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
