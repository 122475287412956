<template>
  <div v-if="visible">
    <el-dialog v-ismove :visible="true" title="分配人员" :before-close="closeTab"
      :close-on-click-modal="false" width="800px">
      <el-row style="height: 300px">
        <span>分配人员：</span>
        <el-select v-model="userName" multiple filterable clearable remote :remote-method="remoteMethod"
          value-key="userId" reserve-keyword placeholder="请选择" style="width: 680px"
          @change="change">
          <el-option v-for="item in userList" :key="item.userId" :value="item"
            :label="item.name+'('+item.username+')'">
          </el-option>
        </el-select>
      </el-row>
      <div slot="footer">
        <el-button size="small" @click="closeTab">取消</el-button>
        <el-button type="primary" size="small" @click="submit">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getUserInfoByIdList } from '@/api/login'
import { deepClone } from '@/utils/index'
import { platformUpdate } from '@/api/platformManagement'
export default {
  name: 'userPanel',
  data() {
    return {
      userList: [],
      userName: []
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    _id: {
      type: Number,
      default: null
    },
    isDisabled: {
      type: Number,
      default: null
    },
    allUsers: {
      type: Array,
      default: function() {
        return []
      }
    },
    selectedIds: {
      type: String,
      default: ''
    }
  },
  watch: {
    'visible'(val) {
      if (val && this.selectedIds) {
        this.getUserMsg(this.selectedIds.split(','))
      }
    }
  },
  methods: {
    // 查询已选用户
    getUserMsg(arr) {
      const params = {
        userIds: arr
      }
      getUserInfoByIdList(params).then(res => {
        if (res.code === '000000') {
          this.userName = deepClone(res.data)
          this.userList = deepClone(res.data)
        }
        console.log(this.userName)
      }).catch()
    },
    // 根据名字查询列表（顾虑allUsers）
    remoteMethod(val) {
      if (val === '') return
      this.userList = this.allUsers.filter(item => item.name.indexOf(val.trim()) > -1)
      if (this.userName.length) {
        this.userName.forEach(item => {
          if (this.userList.filter(el => el.userId === item.userId).length === 0) {
            this.userList.unshift(item)
          }
        })
      }
    },
    closeTab() {
      this.$emit('update:visible', false)
    },
    change(val) {
      console.log(val)
      console.log(this.userList)
    },
    submit() {
      console.log(this.userName)
      console.log(this._id)
      console.log(this.isDisabled)
      const params = {
        id: this._id,
        isDisabled: this.isDisabled,
        userIds: this.userName.map(item => item.userId).join(','),
        userNames: this.userName.map(item => item.name).join(',')
      }
      platformUpdate(params).then(res => {
        if (res.code === '000000') {
          this.$message.success(res.message)
          this.$emit('success')
        } else {
          this.$message.error(res.message)
        }
      }).catch(err => {
        console.log(err)
      })
    }
  }
}
</script>
